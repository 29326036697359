import React from 'react';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Card } from '@frontend/shadcn/components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import { cn } from '@/shadcn/lib/utils';

interface WidgetContainerProps {
  widgetTitle: string;
  widgetIcon?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  textColor?: string;
  bgColor?: string;
  widgetActions?: React.ReactNode;
  tooltip?: string;
}

const WidgetContainer: React.FC<WidgetContainerProps> = ({
  widgetTitle,
  widgetIcon,
  className,
  children,
  textColor = 'text-secondary',
  bgColor = 'bg-primary',
  widgetActions,
  tooltip,
}) => (
  <Card className={cn('rounded-2xl overflow-hidden h-full flex flex-col', className)}>
    <div className={cn('p-3 flex justify-between', bgColor)}>
      <div className="flex gap-1 items-center">
        {widgetIcon}
        <span className={cn('text-sm font-semibold', textColor)}>{widgetTitle}</span>
        {tooltip && (
          <Tooltip>
            <TooltipTrigger asChild>
              <InfoCircledIcon className={cn(textColor, 'cursor-pointer')} />
            </TooltipTrigger>
            <TooltipContent>{tooltip}</TooltipContent>
          </Tooltip>
        )}
      </div>
      <div className="flex gap-1 items-center">
        {widgetActions && widgetActions}
      </div>
    </div>
    <div className="border-solid border-[1px]" />
    <div className="p-4 bg-white flex-grow">{children}</div>
  </Card>
);

export default WidgetContainer;
