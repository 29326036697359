import * as React from 'react';
import { useEffect } from 'react';

import { Radio } from '@revfluence/fresh';
import {
  CircleCheckIcon as CircleCheckIconSolid,
} from '@revfluence/fresh-icons/solid/esm';

import { Comments } from '@frontend/app/components/GroupContentReview/Comments';
import { Guidelines } from '@frontend/app/components/GroupContentReview/Guidelines';
import { TCheckoffGuidelines } from '@frontend/app/types/GroupContentReview';

import { CloseCircleFilled } from '@ant-design/icons';
import { CircleCheckIcon } from '@revfluence/fresh-icons/regular/esm';
import { ActionButtons } from '@frontend/app/components/GroupContentReview/ActionButtons';
import { ActionFeedbackForm } from '@frontend/app/components/GroupContentReview/ActionFeedbackForm';

import { filter, flatMap, map } from 'lodash';
import { TDetails } from '../../types';

import styles from './Details.module.scss';

type IDisplayMode = 'default' | 'reject' | 'approve_with_comments';

const { useState } = React;

export const Details = ({
  comments, guidelines, onApprove, onReject, takingActions, canTakeActions, isLatestVersion,
}: TDetails) => {
  const [view, setView] = React.useState('comments');
  const [displayMode, setDisplayMode] = useState<IDisplayMode>('default');
  const [comment, setComment] = useState('');
  const [guidelineCheckOffs, setGuidelineCheckOffs] = useState<TCheckoffGuidelines[]>([]);

  const onRejectClick = () => {
    const allRules = flatMap(guidelineCheckOffs, (g) => g.rules);
    const selectedRules = filter(allRules, (r) => r.required);
    const selectedGuidelines = map(selectedRules, (r) => r.description);
    onReject(comment, selectedGuidelines);
    setDisplayMode('default');
  };

  useEffect(() => {
    setGuidelineCheckOffs(guidelines.data);
  }, [guidelines]);

  const buttonConfigs = [
    {
      label: 'Reject',
      onClick: () => setDisplayMode('reject'),
      icon: <CloseCircleFilled style={{ color: '#F5222D' }} />,
      disabled: !canTakeActions || !isLatestVersion,
    },
    {
      label: 'Approve with Comments',
      onClick: () => setDisplayMode('approve_with_comments'),
      icon: <CircleCheckIcon style={{ color: '#FAAD14' }} />,
      disabled: !canTakeActions || !isLatestVersion,
    },
    {
      label: 'Approve',
      onClick: onApprove,
      icon: <CircleCheckIconSolid style={{ color: '#389E0D' }} />,
      disabled: !canTakeActions || !isLatestVersion,
    },
  ];

  const onApproveWithCommentsConfig = {
    instructionText: 'Add a comment',
    submissionCTA: 'Approve with Comments',
    onSubmissionClick: () => {
      onApprove({ comment });
      setDisplayMode('default');
    },
  };
  const onRejectConfig = {
    instructionText: 'Provide a reason for rejection and resubmission',
    submissionCTA: 'Submit Rejection',
    onSubmissionClick: onRejectClick,
  };

  return (
    <div className={styles.Details}>
      <Radio.Group
        value={view}
        onChange={(event) => setView(event.target.value)}
        buttonStyle="solid"
        className={styles.navigation}
        size="middle"
      >
        <Radio.Button value="comments">Comments</Radio.Button>
        <Radio.Button value="guidelines">Guidelines</Radio.Button>
      </Radio.Group>
      {displayMode !== 'default' && (
        <ActionFeedbackForm
          comment={comment}
          onCommentChange={(comment) => setComment(comment)}
          guidelineCheckOffs={guidelineCheckOffs}
          onGuidelineChange={(guidelines) => setGuidelineCheckOffs(guidelines)}
          {...(displayMode === 'reject' ? onRejectConfig : onApproveWithCommentsConfig)}
          onCancel={() => setDisplayMode('default')}
        />
      )}
      {displayMode === 'default' && (
        <div className={styles.main}>
          <div className={styles.content}>
            {view === 'comments' && <Comments {...comments} isLatestVersion={isLatestVersion} />}
            {view === 'guidelines' && <Guidelines {...guidelines} />}
          </div>
          <div className={styles.actions}>
            <ActionButtons
              actionOptions={buttonConfigs}
              takingActions={takingActions}
            />
          </div>
        </div>
      )}
    </div>
  );
};
