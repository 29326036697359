import * as React from 'react';
import {
  map, forEach, isEmpty, sortBy, toLower,
} from 'lodash';

import {
  Button, Space, Typography,
} from '@revfluence/fresh';
import { MemberInput } from '@frontend/app/types/globalTypes';
import { MemberFieldSchemasBySectionsQuery_sections_memberData } from '@frontend/app/queries/types/MemberFieldSchemasBySectionsQuery';

import { SelectOffers } from '@frontend/app/containers/Projects/ProjectsPage/SelectOffer/SelectOffer';
import { useState } from 'react';

import { useApolloClient } from '@frontend/applications/AffiliatesApp/hooks';
import { GET_OFFER_BY_ID_QUERY } from '@frontend/applications/AffiliatesApp/queries';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { logger } from '@common';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import styles from './SalesTracking.scss';
import { FieldRow } from '../FieldRow';

const { useMemo, useEffect } = React;
const { Text } = Typography;

type MemberFieldSchema = MemberFieldSchemasBySectionsQuery_sections_memberData;

interface Props {
  member?: MemberInput;
  schemas?: MemberFieldSchema[];
  viewMore?: boolean;
  onViewMoreVisibilityChange?: (visible: boolean) => void;
  memberId?: number;
}

interface SalesTrackingOffer {
  name: string | null;
  promoCode?: string | null;
  link?: string | null;
  activeDates?: string | null;
  memberPageUrl: string | null;
}

const SalesTracking: React.FunctionComponent<Props> = ({
  member,
  schemas,
  viewMore,
  onViewMoreVisibilityChange,
  memberId,
}) => {
  const [showSelectOffer, setShowSelectOffer] = useState(false);
  const staApolloClient = useApolloClient();
  const [offersDetail, setOffersDetail] = useState<GetOfferById_offer[]>([]);
  const migrateToGraphQL = useClientFeatureEnabled(ClientFeature.MIGRATE_TO_GRAPHQL);

  const offers = useMemo(() => {
    if (!member || !schemas || !member.fields[schemas[0].id]) {
      return [];
    }

    try {
      const data = JSON.parse(member.fields[schemas[0].id]) as Record<string, SalesTrackingOffer>;
      const results: Array<SalesTrackingOffer & { id: string }> = [];
      forEach(data, (value, key) => {
        results.push({
          id: key,
          ...value,
        });
      });

      return sortBy(results, (item) => toLower(item.name));
    } catch {
      return [];
    }
  }, [member, schemas]);

  const visibleOffers = useMemo(
    () => (viewMore ? offers : offers.slice(0, 1)),
    [offers, viewMore],
  );
  useEffect(() => {
    onViewMoreVisibilityChange(offers.length > 1);

    const fetchOffersDetails = async () => {
      try {
        const offerDetailPromises = await Promise.all(offers.map(async (offer) => {
          const { data } = await staApolloClient.query({
            query: GET_OFFER_BY_ID_QUERY,
            variables: {
              id: Number(offer.id),
            },
          });
          return data.offer;
        }));

        setOffersDetail((prevOffersDetail) => [
          ...(prevOffersDetail || []),
          ...offerDetailPromises,
        ]);
      } catch (error) {
        logger.error(error.message);
      }
    };
    fetchOffersDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers]);
  if (!member) {
    return null;
  }
  const closeSelectedOffer = () => {
    setShowSelectOffer(false);
  };
  return (
    <Space direction="vertical">
      {migrateToGraphQL && !isEmpty(offers) && <Button style={{ width: '100%' }} onClick={() => setShowSelectOffer(true)}>Manage Offer</Button>}
      {map(visibleOffers, (offer) => (
        <Space
          key={offer.id}
          className={styles.offer}
          direction="vertical"
        >

          <FieldRow
            label="Offer Name"
            value={offer.name}
          />
          {offer.link ? (
            <FieldRow
              label="Link"
              value={offer.link}
              copyable
            />
          ) : (
            <>
              <FieldRow
                label="Promo Code"
                value={offer.promoCode}
                copyable
              />
              <FieldRow
                label="Active Dates"
                value={offer.activeDates}
              />
            </>
          )}
        </Space>
      ))}
      {isEmpty(offers) && (
        <div className={styles.noOffers}>
          <Text type="secondary">
            No sales tracking offers.
          </Text>
        </div>
      )}

      {!isEmpty(offersDetail) && <SelectOffers offers={offersDetail} openSelectedOffer={showSelectOffer} closeSelectedOffer={closeSelectedOffer} selectedMemberIds={[memberId]} setIsVisibleSelectOffer={setShowSelectOffer} />}
    </Space>
  );
};

export default SalesTracking;
