import React, { useEffect } from 'react';
import {
  ColumnDef,
  Row as TableRow,
  useReactTable,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow as ShadcnRow,
} from '@frontend/shadcn/components/ui/table';
import { Card } from '@frontend/shadcn/components/ui/card';
import { Button } from '@frontend/shadcn/components/ui/button';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Download,
  Search,
  Settings,
  Split,
  TriangleAlert,
} from 'lucide-react';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Avatar, AvatarFallback, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { BankOutlined } from '@ant-design/icons';
import { ExportToCsv } from 'export-to-csv';
import { useGetBudgetsWithFiscalYearQuery, useGetProjectBudgetsWithFiscalYearQuery } from '@frontend/app/hooks';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { Link } from 'react-router-dom';

const { useState, useMemo } = React;
interface Row {
  id: number;
  name: string;
  avatarImage?: string;
  sources: string[];
  totalAmount: number;
  allocatedAmount: number;
  remainingAmount: number;
  spent: number;
  quarterDistribution: {
    quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4' | 'Others';
    totalAmount: number;
    allocatedAmount: number;
    spent: number;
    remainingAmount: number;
  }[];
  childData?: Row[];
}

const defaultColumns: ColumnDef<Row>[] = [
  {
    id: 'expander',
    header: () => null,
    cell: ({ row }) => (
      <div>
        {row.getCanExpand() ? (
          <button onClick={row.getToggleExpandedHandler()} className="mr-2">
            {row.getIsExpanded() ? (
              <Button variant="outline" className="w-6 h-6" size="icon">
                <ChevronUp />
              </Button>
            ) : (
              <Button variant="outline" className="w-6 h-6" size="icon">
                <ChevronDown />
              </Button>
            )}
          </button>
        ) : null}
      </div>
    ),
    size: 50,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => {
      const name = row.getValue('name') as string;
      const avatarImage = row.original.avatarImage as string;
      return (
        <div className="flex gap-2 items-center">
          <Avatar className="h-[32px] w-[32px] rounded-none">
            {avatarImage ? (
              <AvatarImage src={avatarImage} className="flex h-full w-full items-center justify-center rounded-lg" />
            ) : (
              <AvatarFallback className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-base text-secondary">
                <BankOutlined />
              </AvatarFallback>
            )}
          </Avatar>
          <span>{name}</span>
        </div>
      );
    },
    size: 250,
  },
  {
    accessorKey: 'sources',
    header: 'Sources',
    cell: ({ row }) => {
      const sources = row.getValue('sources') as string[];
      const extraSources = sources.length > 2 ? sources.length - 2 : 0;
      return (
        <div className="flex gap-1">
          {sources.length === 0 ? (
            <Badge className="rounded-md font-normal" variant="secondary">
              -
            </Badge>
          ) : (
            <>
              {sources.slice(0, 2).map((source, index) => (
                <Badge className="rounded-md font-normal" key={index} variant="secondary">
                  {source}
                </Badge>
              ))}
              {extraSources > 0 && (
                <Badge className="rounded-md font-normal" variant="secondary">
                  {`+${extraSources}`}
                </Badge>
              )}
            </>
          )}
        </div>
      );
    },
    size: 250,
  },
  {
    accessorKey: 'totalAmount',
    header: () => <div className="text-right">Total Budget</div>,
    cell: ({ row }) => {
      const totalAmount = parseFloat(row.getValue('totalAmount'));

      // Format the amount as a dollar amount
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(totalAmount);

      return <div className="text-right">{formatted}</div>;
    },
    size: 200,
  },
  {
    accessorKey: 'allocatedAmount',
    header: () => <div className="text-right">Allocated</div>,
    cell: ({ row }) => {
      const allocatedAmount = parseFloat(row.getValue('allocatedAmount'));
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(allocatedAmount);

      return <div className="text-right">{formatted}</div>;
    },
    size: 200,
  },
  {
    accessorKey: 'spent',
    header: () => <div className="text-right">Spent</div>,
    cell: ({ row }) => {
      const spent = parseFloat(row.getValue('spent'));
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(spent);

      return <div className="text-right">{formatted}</div>;
    },
    size: 200,
  },
  {
    accessorKey: 'remainingAmount',
    header: () => <div className="text-right">Remaining</div>,
    cell: ({ row }) => {
      const remainingAmount = parseFloat(row.getValue('remainingAmount'));
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(remainingAmount);

      return (
        <div className={`flex gap-1 justify-end items-center ${remainingAmount < 0 ? 'text-[#D48806]' : 'text-right'}`}>
          {formatted}
          {remainingAmount < 0 && <TriangleAlert size={16} />}
        </div>
      );
    },
    size: 200,
  },
];

interface IProps {
  source: string;
  fiscalYear: string;
}
const PAGE_SIZE = 10;
export const BudgetDetailedView: React.FC<IProps> = (props) => {
  const { source, fiscalYear } = props;
  const [data, setData] = useState<Row[]>([]);
  const [splitBudget, setSplitBudget] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data: budgetData, loading: loadingBudgetWithFiscalYear } = useGetBudgetsWithFiscalYearQuery({
    fetchPolicy: 'cache-and-network',
    variables: { fiscalYear },
    skip: source !== 'Budget',
  });

  const {
    data: projectBudgetData,
    loading: loadingProjectBudgetWithFiscalYear,
  } = useGetProjectBudgetsWithFiscalYearQuery({
    fetchPolicy: 'cache-and-network',
    variables: { fiscalYear },
    skip: source !== 'Project',
  });

  useEffect(() => {
    if (!loadingBudgetWithFiscalYear && budgetData?.getBudgetDashboardDetailedViewTabBudgetData.length) {
      const budgets = budgetData.getBudgetDashboardDetailedViewTabBudgetData.map((budget) => {
        const childData = budget?.childBudgetsList?.map((childBudget) => ({
          id: childBudget.budgetAccountId,
          allocatedAmount: childBudget.allocatedAmount,
          spent: childBudget.spentAmount,
          totalAmount: childBudget.totalAmount,
          name: childBudget.budgetName,
          sources: childBudget?.parentBudgetName ? [childBudget.parentBudgetName] : [],
          remainingAmount: childBudget.totalAmount - (childBudget.allocatedAmount + childBudget.spentAmount),
          quarterDistribution:
            childBudget?.quarterDistributions?.map((quarter) => ({
              quarter: quarter.quarterKey === 'QO' ? 'Others' : quarter.quarterKey,
              totalAmount: quarter.totalAmount,
              allocatedAmount: quarter.allocatedAmount,
              spent: quarter.spentAmount,
              remainingAmount: quarter.allocatedAmount,
            })) || [],
          })) || [];

        return {
          id: budget.budgetAccountId,
          allocatedAmount: budget.allocatedAmount,
          spent: budget.spentAmount,
          totalAmount: budget.totalAmount,
          name: budget.budgetName,
          sources: budget?.parentBudgetName ? [budget.parentBudgetName] : [],
          remainingAmount: budget.totalAmount - (budget.allocatedAmount + budget.spentAmount),
          quarterDistribution:
            budget?.quarterDistributions?.map((quarter) => ({
              quarter: quarter.quarterKey === 'QO' ? 'Others' : quarter.quarterKey,
              totalAmount: quarter.totalAmount,
              allocatedAmount: quarter.allocatedAmount,
              spent: quarter.spentAmount,
              remainingAmount: quarter.totalAmount - (quarter.allocatedAmount + quarter.spentAmount),
            })) || [],
          childData,
        };
      });

      setData(budgets);
    } else if (!loadingProjectBudgetWithFiscalYear && projectBudgetData?.getBudgetDashboardDetailedViewTabProjectData.length) {
      const projectBudgets = projectBudgetData.getBudgetDashboardDetailedViewTabProjectData.map((budget) => ({
        id: budget.programId,
        allocatedAmount: budget.allocatedAmount,
        spent: budget.spentAmount,
        totalAmount: budget.totalAmount,
        name: budget.programName,
        sources: budget.sources,
        remainingAmount: budget.totalAmount - (budget.allocatedAmount + budget.spentAmount),
        quarterDistribution:
          budget?.quarterDistributions?.map((quarter) => ({
            quarter: quarter.quarterKey === 'QO' ? 'Others' : quarter.quarterKey,
            totalAmount: quarter.totalAmount,
            allocatedAmount: quarter.allocatedAmount,
            spent: quarter.spentAmount,
            remainingAmount: quarter.totalAmount - (quarter.allocatedAmount + quarter.spentAmount),
          })) || [],
      }));

      setData(projectBudgets);
    } else {
      setData([]);
    }
  }, [loadingBudgetWithFiscalYear, loadingProjectBudgetWithFiscalYear, projectBudgetData, budgetData]);

  const filteredData = useMemo(() => data.filter((row) => row.name.toLowerCase().includes(searchTerm.toLowerCase())), [data, searchTerm]);
  useEffect(() => {
    setCurrentPage(1);
  }, [source, fiscalYear, searchTerm]);
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / PAGE_SIZE);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, currentPage]);

  const createQuarterColumns = (quarter: string) => [
    {
      id: `${quarter.toLowerCase()}TotalBudget`,
      header: () => <div className="text-right">Total Budget</div>,
      cell: ({ row }) => {
        const totalAmount = row.original.quarterDistribution.find((q) => q.quarter === quarter)?.totalAmount || 0;
        return (
          <div className="text-right">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(totalAmount)}
          </div>
        );
      },
      size: 200,
    },
    {
      id: `${quarter.toLowerCase()}Allocated`,
      header: () => <div className="text-right">Allocated</div>,
      cell: ({ row }) => {
        const allocatedAmount = row.original.quarterDistribution.find((q) => q.quarter === quarter)?.allocatedAmount || 0;
        return (
          <div className="text-right">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(allocatedAmount)}
          </div>
        );
      },
      size: 200,
    },
    {
      id: `${quarter.toLowerCase()}Spent`,
      header: () => <div className="text-right">Spent</div>,
      cell: ({ row }) => {
        const spent = row.original.quarterDistribution.find((q) => q.quarter === quarter)?.spent || 0;
        return (
          <div className="text-right">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(spent)}
          </div>
        );
      },
      size: 200,
    },
    {
      id: `${quarter.toLowerCase()}Remaining`,
      header: () => <div className="text-right">Remaining</div>,
      cell: ({ row }) => {
        const remaining = row.original.quarterDistribution.find((q) => q.quarter === quarter)?.remainingAmount || 0;
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(remaining);

        return (
          <div className={`flex gap-1 justify-end items-center ${remaining < 0 ? 'text-[#D48806]' : 'text-right'}`}>
            {formatted}
            {remaining < 0 && <TriangleAlert size={16} />}
          </div>
        );
      },
      size: 200,
    },
  ];

  const columns = useMemo<ColumnDef<Row>[]>(() => {
    let finalColumns = [...defaultColumns];

    if (splitBudget) {
      const quarterBorderColors: Record<string, string> = {
        Q1: 'border-blue-500',
        Q2: 'border-green-500',
        Q3: 'border-yellow-500',
        Q4: 'border-red-500',
        Others: 'border-pink-500',
      };
      const quarters = ['Q1', 'Q2', 'Q3', 'Q4', 'Others'];

      finalColumns = [
        ...finalColumns,
        ...quarters.map((quarter) => ({
          id: quarter,
          header: () => (
            <div className={`m-0 p-0 border-b-4 ${quarterBorderColors[quarter] || 'border-gray-300'}`}>
              <div className="text-center">{quarter}</div>
            </div>
          ),
          columns: createQuarterColumns(quarter),
        })),
      ];
    }
    finalColumns.push({
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => {
        const budget = row.original;

        return (
          <Button variant="outline" className="w-7 h-6 rounded-lg" size="icon">
            <Link
              to={
                source === 'Budget'
                  ? `/settings/budget/editBudget/${budget.id}`
                  : `/projects/${budget.id}/settings/budget`
              }
            >
              <Settings className="h-4 w-4 text-gray-500 m-2" />
            </Link>
          </Button>
        );
      },
      size: 200,
      maxSize: 400,
    });
    return finalColumns;
  }, [splitBudget, source]);

  const exportBudgetData = () => {
    const options = {
      headers: ['Name', 'Sources', 'Total Budget', 'Allocated', 'Spent'],
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      title: 'Budget Data',
    };

    const csvExporter = new ExportToCsv(options);
    const dataToExport = data.map((budgetData) => ({
      name: budgetData.name,
      sources: budgetData.sources.join(', '),
      totalAmount: budgetData.totalAmount,
      allocatedAmount: budgetData.allocatedAmount,
      spent: budgetData.spent,
    }));

    csvExporter.generateCsv(dataToExport);
  };

  const table = useReactTable<Row>({
    data: paginatedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.childData || [],
  });

  const renderRows = (rows: TableRow<Row>[]) =>
    rows.flatMap((row) => [
      <React.Fragment key={row.id}>
        <ShadcnRow className={row.depth > 0 ? 'bg-gray-50' : ''}>
          {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
          ))}
        </ShadcnRow>
      </React.Fragment>,
    ]);

  const loading = loadingBudgetWithFiscalYear || loadingProjectBudgetWithFiscalYear;
  if (loading) {
    return (
      <div className="p-8 bg-secondary">
        <Card className="p-2">
          <Skeleton className="h-8 w-full my-4" />
          <Skeleton className="h-8 w-full my-4" />
          <Skeleton className="h-8 w-full my-4" />
          <Skeleton className="h-8 w-full my-4" />
        </Card>
      </div>
    );
  }
  return (
    <div className="p-8 bg-secondary">
      <Card className="p-6">
        <div className="flex items-center justify-between py-2">
          <div>
            <span>{`${filteredData.length} Budget Accounts`}</span>
          </div>
          <div className="flex items-center gap-2">
            <Button variant="outline" className="w-8 h-8" size="icon" onClick={() => setSplitBudget(!splitBudget)}>
              <Split className="text-gray-500" size={16} />
            </Button>
            <Button variant="outline" className="w-8 h-8" size="icon" onClick={exportBudgetData}>
              <Download className="text-gray-500" size={16} />
            </Button>
            <div className="relative ml-auto flex-1 md:grow-0">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                type="search"
                placeholder="Search"
                className="w-full rounded-lg bg-background pl-8 md:w-[200px]"
              />
            </div>
            <div className="flex gap-2 items-center">
              <span>{`${(currentPage - 1) * PAGE_SIZE + 1}-${Math.min(currentPage * PAGE_SIZE, totalItems)} of ${totalItems}`}</span>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  className="w-8 h-8"
                  size="icon"
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  <ChevronLeft className="text-gray-500" size={16} />
                </Button>
                <Button
                  variant="ghost"
                  className="w-8 h-8"
                  size="icon"
                  disabled={currentPage === totalPages || totalItems === 0}
                  onClick={handleNextPage}
                >
                  <ChevronRight className="text-gray-500" size={16} />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table className="min-w-full divide-y divide-gray-200">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <ShadcnRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width:
                        header.getSize() !== 150 ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </ShadcnRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length > 0 ? (
              renderRows(table.getRowModel().rows)
            ) : (
              <ShadcnRow>
                <TableCell colSpan={table.getAllColumns().length} className="text-center py-4">
                  No data available
                </TableCell>
              </ShadcnRow>
            )}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};
