import * as React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import {
  compact,
  flatMap,
  filter,
  find,
  first,
  groupBy,
  includes,
  isEmpty,
  map,
  maxBy,
  min,
  size,
  sumBy,
  uniq,
  uniqBy,
} from 'lodash';

import { Empty } from 'antd';
import { Tag, Typography, Avatar } from '@revfluence/fresh';
import { getNetworkByPostType, LoadSpinner } from '@components';
import {
  ContentReviewStatusTag,
  TContentTableRow,
  TContentToolbarCounter,
  TContentTableColumn,
  ContentReviewTable,
} from '@frontend/app/components';

import {
  IContentReview,
  IMember,
  useClientFeatureEnabled,
  useGetGroupContentReviews,
  useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';
import { TProject } from '@frontend/app/containers/Projects/types';
import { ContentReviewStatus, RawContentReviewState } from '@frontend/app/types/globalTypes';
import { getContentReviewIcon, getContentReviewMedia } from '@frontend/app/utils';
import { ClientFeature } from '@frontend/app/constants';
import { Link } from 'react-router-dom';
import { ContentReviewPanel } from './ContentReviewPanel';
import { ContentReviewGroup, ContentReviewGroupLabel, StatusesForGroup } from './constants';

import styles from './GroupContentReviewPage.scss';
import { GetContentReviewsQuery_reviews } from '../../../../queries/types/GetContentReviewsQuery';

interface IProps {
  project: TProject;

  className?: string;
}

const { useMemo, useState, useCallback } = React;

const { Text } = Typography;

const trimName = (name: string) =>
  name
    .split(' ')
    .map((s) => s[0])
    .join('');

const renderAvatar = (_: string, record: TContentTableRow) => {
  // Don't render user avatar for Table expandable sub rows
  if (!record.children) return <span />;

  if (record.avatar) {
    return <Avatar src={record.avatar} />;
  }

  return <Avatar style={{ backgroundColor: '#923562' }}>{trimName(record?.memberData?.name)}</Avatar>;
};

const renderName = (_: string, record: TContentTableRow) => {
  // Don't render user name for Table expandable sub rows
  if (!record.children) return <span />;

  return record?.memberData?.name;
};

const columns: TContentTableColumn[] = [
  {
    dataIndex: 'memberData',
    key: 'memberData',
    render: (_: string, record: TContentTableRow) => (
      <div className={styles.nameColumn}>
        {renderAvatar(_, record)}
        <Link
          to={{
            pathname: '/creator',
            search: `?creatorId=${record.memberData?.creatorId}`,
          }}
          target="_blank"
        >
          {renderName(_, record)}
        </Link>
      </div>
    ),
    sorter: (a, b) => a?.memberData?.name.localeCompare(b?.memberData?.name),
    defaultSortOrder: 'ascend', // Set default sorting order
    fixed: 'left',
  },
  {
    title: <Text strong>Instagram</Text>,
    dataIndex: 'instagram',
    key: 'instagram',
    sorter: (a, b) => a?.instagram?.localeCompare(b?.instagram),
  },
  {
    title: <Text strong>Tiktok</Text>,
    dataIndex: 'tiktok',
    key: 'tiktok',
    sorter: (a, b) => a.tiktok?.localeCompare(b?.tiktok),
  },
  {
    title: <Text strong>Deliverables</Text>,
    dataIndex: 'content',
    key: 'content',
    sorter: (a, b) => a?.totalDeliverables - b?.totalDeliverables,
  },
  {
    title: <Text strong>Comments</Text>,
    dataIndex: 'totalComments',
    key: 'totalComments',
  },
  {
    title: <Text strong>Content</Text>,
    dataIndex: 'deliverables',
    key: 'deliverables',
  },
  {
    title: <Text strong>Status</Text>,
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: <Text strong>Due Date</Text>,
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => {
      const dateA = a?.date ? new Date(a.date).getTime() : 0;
      const dateB = b?.date ? new Date(b.date).getTime() : 0;
      return dateA - dateB;
    },
  },
  {
    title: <Text strong>Last Reviewer</Text>,
    dataIndex: 'lastReviewer',
    key: 'lastReviewer',
    sorter: (a, b) => a?.lastReviewer?.localeCompare(b?.lastReviewer),
  },
  {
    title: <Text strong>Last Action Date</Text>,
    dataIndex: 'lastActionDateTime',
    key: 'lastActionDateTime',
    render: (value) => <span>{value ? format(new Date(value), 'MMM d, yyyy') : '-'}</span>,
    sorter: (a, b) => {
      const dateA = a?.lastActionDateTime ? new Date(a.lastActionDateTime).getTime() : 0;
      const dateB = b?.lastActionDateTime ? new Date(b.lastActionDateTime).getTime() : 0;
      return dateA - dateB;
    },
  },
];

/**
 * @type {React.FC}
 */
export const GroupContentReviewPage: React.FC<IProps> = React.memo(({ project, className }) => {
  const [selectedReview, setSelectedReview] = useState<IContentReview>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<ContentReviewGroup>(ContentReviewGroup.All);
  const include_completed = useClientFeatureEnabled(ClientFeature.INCLUDE_COMPLETED_CONTENT_REVIEWS);
  const submissionHistoryFeatureFlag = useClientFeatureEnabled(ClientFeature.GCR_SUBMISSION_HISTORY_ENABLED);
  const gcrVersionSwitch = useClientFeatureEnabled(ClientFeature.GCR_VERSION_SWITCH);

  const { data: { schemas: memberFieldSchemas = null } = {} } = useMemberFieldSchemasQuery();

  const instagramKey = useMemo(() => {
    if (!memberFieldSchemas) {
      return null;
    }

    const schema = find(memberFieldSchemas, (s) => s.name === 'Instagram');

    return schema?.id;
  }, [memberFieldSchemas]);

  const tiktokKey = useMemo(() => {
    if (!memberFieldSchemas) {
      return null;
    }

    const schema = find(memberFieldSchemas, (s) => s.name === 'TikTok');

    return schema?.id;
  }, [memberFieldSchemas]);

  const rawStates: RawContentReviewState[] = [
    RawContentReviewState.CONTENT_REVIEW_STATE_AMENDED,
    RawContentReviewState.CONTENT_REVIEW_STATE_APPROVED,
    RawContentReviewState.CONTENT_REVIEW_STATE_NEW,
    RawContentReviewState.CONTENT_REVIEW_STATE_PLACEHOLDER,
    RawContentReviewState.CONTENT_REVIEW_STATE_REJECTED,
    RawContentReviewState.CONTENT_REVIEW_STATE_UPLOADED,
    RawContentReviewState.CONTENT_REVIEW_STATE_VALIDATING_UPLOAD,
    RawContentReviewState.CONTENT_REVIEW_STATE_WAITING_FOR_SUBMISSION,
  ];

  if (include_completed) {
    rawStates.push(
      RawContentReviewState.CONTENT_REVIEW_STATE_COMPLETED,
      RawContentReviewState.CONTENT_REVIEW_STATE_COMPLETED_WITHOUT_PAYMENT,
    );
  }

  const { loading, reviews } = useGetGroupContentReviews({
    variables: {
      programId: project?.id,
      rawStates,
    },
  });

  const isContentLive = useCallback(
    (review: GetContentReviewsQuery_reviews) =>
      !!review?.info?.raw?.live_upload_link || !!review?.info?.raw?.content?.link,
    [],
  );

  const filteredReviews = useMemo(() => {
    const statues = StatusesForGroup[selectedGroup];
    return filter(reviews, (r: GetContentReviewsQuery_reviews) => {
      if (!r.info.raw?.product) return false;

      const isLive = isContentLive(r);
      const reviewStatus = isLive ? 'Live' : r.status;
      if (selectedGroup === ContentReviewGroup.Live) return isLive;
      else if (selectedGroup === ContentReviewGroup.All) return includes([...statues, 'Live'], reviewStatus);
      return r.isGCR && includes(statues, reviewStatus) && reviewStatus !== ContentReviewStatus.ChangesRequested;
    });
  }, [reviews, selectedGroup, isContentLive]);

  const members: IMember[] = useMemo(() => {
    if (loading) {
      return [];
    }

    return uniqBy(compact(map(filteredReviews, (r) => r.member)), (m) => m.id);
  }, [loading, filteredReviews]);

  const rows: TContentTableRow[] = useMemo(() => {
    if (loading) {
      return [];
    }

    const reviewsByMember = groupBy(
      filter(filteredReviews, (review: GetContentReviewsQuery_reviews) => {
        const member: IMember | undefined = find(members, (m) => m.id == review.memberId);
        return (
          !!member
          && (member.name.toLowerCase().includes(searchText.toLowerCase())
            || (submissionHistoryFeatureFlag
              && (member.fields?.[instagramKey]?.toLowerCase().includes(searchText.toLowerCase())
                || member.fields?.[tiktokKey]?.toLowerCase().includes(searchText.toLowerCase()))))
        );
      }),
      (r: GetContentReviewsQuery_reviews) => r.memberId,
    );

    return map(reviewsByMember, (reviews: GetContentReviewsQuery_reviews[], memberId) => {
      const member: IMember = find(members, (m) => m.id === parseInt(memberId, 10));
      const nextDueTsForMember = min(compact(map(reviews, (r) => r.info.raw.next_deadline)));
      const uniqueStates = uniq(map(reviews, (r) => r.info.raw.state));
      const allStatusTags = [<ContentReviewStatusTag key="status" state={first(uniqueStates)} />];
      if (size(uniqueStates) > 1) {
        allStatusTags.push(
          <Tag key="extra" color="default">
            +
            {size(uniqueStates) - 1}
          </Tag>,
        );
      }

      let latestReview;
      let latestActionDateTime;
      const latestReviewByContentId = {};
      reviews.forEach((review: GetContentReviewsQuery_reviews) => {
        const reviewerRecentComment = maxBy(review.comments, 'createdDate');
        const reviewerRecentEvent = maxBy(review.events, 'createdDate');
        let latestReviewForDeliverable = null;
        let latestActionDateTimeForDeliverable = null;

        if (reviewerRecentComment && reviewerRecentEvent) {
          if (reviewerRecentComment.createdDate > reviewerRecentEvent.createdDate) {
            latestReviewForDeliverable = reviewerRecentComment;
            latestActionDateTimeForDeliverable = reviewerRecentComment.createdDate;
          } else {
            latestReviewForDeliverable = reviewerRecentEvent;
            latestActionDateTimeForDeliverable = reviewerRecentEvent.createdDate;
          }
        } else if (reviewerRecentComment) {
          latestReviewForDeliverable = reviewerRecentComment;
          latestActionDateTimeForDeliverable = reviewerRecentComment.createdDate;
        } else if (reviewerRecentEvent) {
          latestReviewForDeliverable = reviewerRecentEvent;
          latestActionDateTimeForDeliverable = reviewerRecentEvent.createdDate;
        }

        latestReviewByContentId[review.id] = {
          latestReviewForDeliverable,
          latestActionDateTimeForDeliverable,
        };
        if (!latestActionDateTime || reviewerRecentComment?.createdDate > latestActionDateTime) {
          latestReview = reviewerRecentComment;
          latestActionDateTime = reviewerRecentComment?.createdDate;
        }
        if (!latestActionDateTime || reviewerRecentEvent?.createdDate > latestActionDateTime) {
          latestReview = reviewerRecentEvent;
          latestActionDateTime = reviewerRecentEvent?.createdDate;
        }
      });

      const latestReviewer = latestReview?.user?.name;

      return {
        key: memberId,
        id: memberId,
        name: member.name,
        avatar: member.profilePicture,
        statusTags: allStatusTags,
        tiktok: member.fields[tiktokKey] || ' - ',
        instagram: member.fields[instagramKey] || ' - ',
        totalContent: size(reviews),
        lastCommentDate: (() => {
          const latestCommentDate = maxBy(
            flatMap(reviews, (review) => review.comments ?? []),
            'createdDate',
          )?.createdDate;
          if (latestCommentDate) {
            return format(new Date(latestCommentDate), 'MMM dd, yyyy');
          } else {
            return '-';
          }
        })(),
        totalComments: sumBy(reviews, (r) => r.comments?.length || 0),
        totalDeliverables: sumBy(reviews, (r) => r.info.raw.product.count || 0),
        lastReviewer: latestReviewer,
        lastActionDateTime: latestActionDateTime,
        children: map(reviews, (review: GetContentReviewsQuery_reviews) => {
          const dueTs = review.info.raw.next_deadline;
          const deliverables = getContentReviewMedia(review.info.raw);

          return {
            key: review.id,
            id: review.id,
            name: member.name,
            totalComments: review?.comments?.length || 0,
            lastCommentDate: review.comments?.length
              ? format(new Date(maxBy(review.comments)?.createdDate), 'MMM dd, yyyy')
              : '-',
            content: {
              icon: getContentReviewIcon(getNetworkByPostType(review.info.backendServer.postType)),
              description: review.info.raw.product?.product_description,
              link: '#',
              deliverables,
              onClick: () => setSelectedReview(review),
            },
            statusTags: <ContentReviewStatusTag state={review.info.raw.state} />,
            date: dueTs > 0 ? format(new Date(dueTs * 1000), 'MMM dd, yyyy') : '-',
            lastReviewer: latestReviewByContentId?.[review.id]?.latestReviewForDeliverable?.user?.name,
            lastActionDateTime: latestReviewByContentId?.[review.id]?.latestActionDateTimeForDeliverable,
          };
        }),
        date: nextDueTsForMember > 0 ? format(new Date(nextDueTsForMember * 1000), 'MMM dd, yyyy') : '-',
        memberData: {
          name: member.name,
          creatorId: member.id,
        },
      };
    });
  }, [loading, members, filteredReviews, instagramKey, tiktokKey, searchText, submissionHistoryFeatureFlag]);

  const counters: TContentToolbarCounter[] = useMemo(() => {
    if (loading) {
      return [];
    }

    return map(ContentReviewGroup, (group) => {
      const statuses = StatusesForGroup[group];
      let count;

      if (group === ContentReviewGroup.Live) count = size(filter(reviews, (r) => isContentLive(r)));
      else if (group === ContentReviewGroup.All) {
        count = size(filter(reviews, (r) => includes(statuses, r.status) || isContentLive(r)));
      } else count = size(filter(reviews, (r) => includes(statuses, isContentLive(r) ? 'Live' : r.status)));

      return {
        id: group,
        name: ContentReviewGroupLabel[group],
        count,

        selected: group === selectedGroup,
        onClick: () => setSelectedGroup(group),
      };
    });
  }, [loading, reviews, selectedGroup, isContentLive]);

  const onCloseReviewPanel = useCallback(() => setSelectedReview(null), []);

  const handleSearchTextChange = useCallback((e) => setSearchText(e.target.value), []);

  if (!project) {
    return <LoadSpinner />;
  }

  return (
    <div className={cx(styles.GroupContentReviewPage, className)}>
      {loading && <LoadSpinner />}
      {!loading && isEmpty(reviews) && <Empty />}
      {!loading && !isEmpty(reviews) && (
        <ContentReviewTable
          columns={columns}
          counters={counters}
          rows={rows}
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
        />
      )}
      <ContentReviewPanel reviewId={selectedReview?.id} open={!!selectedReview} onRequestClose={onCloseReviewPanel} gcrVersionSwitch={gcrVersionSwitch} />
    </div>
  );
});

GroupContentReviewPage.defaultProps = {
  className: null,
};

GroupContentReviewPage.displayName = 'GroupContentReviewPage';
