import * as React from 'react';
import { format } from 'date-fns';
import {
  each, includes, isEmpty, map, size, sortBy, split,
} from 'lodash';

import { Drawer, Select } from '@revfluence/fresh';
import {
  ClipboardListCheckIcon,
} from '@revfluence/fresh-icons/regular/esm';
import {
  getNetworkByPostType, LoadSpinner, NetworkIcon,
} from '@components';

import { ContentReviewStatus } from '@frontend/app/types/globalTypes';
import {
  useCreateContentReviewComment,
  useGetContentReviewById,
  useGetCurrentClient,
  useGetProfile,
  useGuestApproveContentReview,
  useGuestRejectContentReview,
} from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';
import { getContentReviewMedia } from '@frontend/app/utils';
import { IMemberData } from '@frontend/app/components';
import { useState } from 'react';
import { getContentReviewCommments } from './utils';
import {
  TContentType, TContentComments, TContentGallery, TContentGuidelines, ContentDetails,
} from './ContentDetails';

import styles from './ContentReviewPanel.scss';

interface IProps {
  reviewId: number;
  open: boolean;
  onRequestClose(): void;
  gcrVersionSwitch: boolean;
}

enum ContentVersion {
  LATEST_VERSION = 'Latest Version',
}

const { useMemo, useCallback } = React;

export const ContentReviewPanel: React.FC<Readonly<IProps>> = React.memo(
  ({
    open,
    onRequestClose,
    reviewId,
    gcrVersionSwitch,
  }) => {
    const { user } = useAuth();
    const { approveReview, loading: approving } = useGuestApproveContentReview();
    const { rejectReview, loading: rejecting } = useGuestRejectContentReview();
    const { createComment, loading: creatingComment } = useCreateContentReviewComment();
    const [selectedVersion, setSelectedVersion] = useState(ContentVersion.LATEST_VERSION);

    const { loading: loadingClient, client } = useGetCurrentClient();
    const { loading: loadingProfile, profile } = useGetProfile();
    const { loading: loadingReview, review, refetch } = useGetContentReviewById({
      variables: {
        id: reviewId,
      },
    });

    const isContentApprover = useMemo(() => {
      if (!user) {
        return false;
      }

      const roles = user['https://aspirex.api.com/roles'];

      return includes(roles, 'manager:content_approver');
    }, [user]);
    const takingActions = useMemo(() => approving || rejecting, [approving, rejecting]);
    const onCreateComment = useCallback(async (text: string) => {
      await createComment({
        variables: {
          params: {
            reviewId,
            info: {
              text,
            },
          },
        },
      });

      refetch();
    }, [reviewId, createComment, refetch]);
    const loading = useMemo(
      () => loadingClient || loadingProfile || loadingReview,
      [loadingClient, loadingProfile, loadingReview],
    );
    const isPendingReview = useMemo(() => review?.status === ContentReviewStatus.Pending, [review]);
    const onApproveReview = useCallback(async (params?: {
      comment: string;
      guidelines?: string[];
    }) => {
      await approveReview({
        variables: {
          id: reviewId,
          comment: params?.comment,
          guidelines: params?.guidelines,
        },
      });
    }, [reviewId, approveReview]);
    const onRejectReview = useCallback(async (comment: string, guidelines?: string[]) => {
      await rejectReview({
        variables: {
          id: reviewId,
          comment,
          guidelines,
        },
      });
    }, [reviewId, rejectReview]);
    const gallery: TContentGallery = useMemo(() => {
      if (!review) {
        return null;
      }

      const content = review.info.raw.content;
      const media = getContentReviewMedia(review.info.raw);
      let contents: TContentType[] = map(media, (m) => ({
        type: m.type === 'video' ? 'video' : 'image',
        src: m.url,
      }));
      if (isEmpty(contents) && content?.content_class === 'YoutubeVideoContent') {
        contents.push({
          type: 'youtube',
          src: content.link,
          coverSrc: content.image_link,
        });
      }
      const versions = review.info.raw?.rejected_content || [];

      const sortedVersions = sortBy(versions, 'date_created');
      const selectedIndex = parseInt(selectedVersion.replace('Version ', ''), 10) - 1;
      const selectedContent = sortedVersions[selectedIndex];

      if (selectedVersion !== ContentVersion.LATEST_VERSION) {
        if (sortedVersions[selectedIndex]) {
          contents = [];

          if (selectedContent.content_class === 'YoutubeVideoContent') {
            contents.push({
              type: 'youtube',
              src: selectedContent.link,
              coverSrc: selectedContent.image_link,
            });
          } else {
            selectedContent.media.forEach((m) => {
              if (m.category === 'downloadable_media') {
                contents.push({
                  type: selectedContent.media_type === 'video' ? 'video' : 'image',
                  src: m.url,
                });
              }
            });
          }
        }
      }

      return {
        icon: <NetworkIcon identifier={getNetworkByPostType(review.info.backendServer.postType)} />,
        versions,
        title: review.info.raw.product?.product_description,
        caption: selectedVersion === ContentVersion.LATEST_VERSION ? content?.caption : selectedContent?.caption,
        description: '',
        resources: contents,
        createdDate: selectedVersion === ContentVersion.LATEST_VERSION ? content?.date_created : selectedContent?.date_created,
      };
    }, [review, selectedVersion]);
    const comments: TContentComments = useMemo(() => {
      if (!review) {
        return null;
      }

      const submittedTs = review.info.raw.date_first_upload;

      return {
        title: `${submittedTs ? format(submittedTs * 1000, 'MM/dd/yyyy') : '-'} • ${review.member.name} submitted content`,
        comments: getContentReviewCommments({ review, client, profile }),
        creatingComment,
        onCreateComment: async (text: string) => {
          await onCreateComment(text);
        },
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewId, review, profile, client, approveReview, takingActions, creatingComment, onCreateComment]);
    const guidelines: TContentGuidelines = useMemo(() => {
      if (!review) {
        return null;
      }

      const checkoffGuidelines = review.info.raw.product?.checkoff_guidelines;
      const guidelinesForKey: Record<string, string[]> = {};
      const separator = ': ';
      each(checkoffGuidelines, (c) => {
        const parts = split(c, separator);
        const key = parts[0];
        let value: string;

        if (size(parts) === 2) {
          value = parts[1];
        } else if (size(parts) > 2) {
          value = parts.slice(1).join(separator);
        }

        if (!guidelinesForKey[key]) {
          guidelinesForKey[key] = [];
        }

        guidelinesForKey[key].push(value);
      });

      const guidelines: TContentGuidelines = {
        data: map(guidelinesForKey, (guidelines, key) => ({
          id: key,
          title: key,
          rules: map(guidelines, (description, index) => ({
            id: `${index}`,
            required: false,
            description,
          })),
        })),
      };

      // check if example contents exists
      if (!isEmpty(review.info.raw.product?.deliverable_guidelines.example_content_guidelines)) {
        guidelines.exampleContents = map(review.info.raw.product.deliverable_guidelines.example_content_guidelines, ({ media }) => ({
          type: media.media_type === 'video' ? 'video' : 'image',
          src: media.url,
        }));
      }

      return guidelines;
    }, [review]);

    const memberData: IMemberData = useMemo(() => {
      if (!review) {
        return null;
      }

      return {
        name: review.member.name,
        creatorId: review.member.id,
        creatorProfilePicture: review.member.profilePicture,
      };
    }, [review]);

    const handleVersionChange = useCallback((value) => {
      setSelectedVersion(value);
    }, []);
    const renderDropdownOptions = () => {
      const options = [
        <Select.Option key="latest" value={ContentVersion.LATEST_VERSION}>
          {ContentVersion.LATEST_VERSION}
        </Select.Option>,
      ];

      if (gallery?.versions) {
        gallery.versions.slice().reverse().forEach((_version, index) => {
          const versionNumber = gallery.versions.length - index;
          options.push(
            <Select.Option key={`version-${versionNumber}`} value={`Version ${versionNumber}`}>
              Version
              {' '}
              {versionNumber}
            </Select.Option>,
          );
        });
      }

      return options;
    };
    const versionSelect = (
      gcrVersionSwitch ? (
        <Select value={selectedVersion} onChange={handleVersionChange}>
          {renderDropdownOptions()}
        </Select>
      ) : null
    );

    const closeDrawer = useCallback(() => {
      setSelectedVersion(ContentVersion.LATEST_VERSION);
      onRequestClose();
    }, [onRequestClose]);
    return (
      <Drawer
        className={styles.ContentReviewPanel}
        destroyOnClose
        onClose={closeDrawer}
        visible={open}
        width={1024}
        footer={null}
        title={(
          <div className={styles.title}>
            <span className={styles.icon}>
              <ClipboardListCheckIcon className={styles.svg} />
            </span>
            Group Content Review:
            {' '}
            {gallery?.title}
          </div>
        )}
      >
        {loading && <LoadSpinner />}
        {!loading && review && (
          <ContentDetails
            gallery={gallery}
            comments={comments}
            guidelines={guidelines}
            canTakeActions={isContentApprover && isPendingReview}
            onApprove={onApproveReview}
            onReject={onRejectReview}
            takingActions={takingActions}
            memberData={memberData}
            isLatestVersion={selectedVersion === ContentVersion.LATEST_VERSION}
            versionSelector={versionSelect}
          />
        )}
      </Drawer>
    );
  },
);
